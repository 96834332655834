// Recidiviz - a data platform for criminal justice reform
// Copyright (C) 2024 Recidiviz, Inc.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
// =============================================================================

import {
  FOOTER_HEIGHT_WITHOUT_MARGIN,
  HEADER_BAR_HEIGHT,
  MIN_DESKTOP_WIDTH,
  MIN_TABLET_WIDTH,
  palette,
  typography,
} from "@justice-counts/common/components/GlobalStyles";
import styled from "styled-components/macro";

import { OpacityGradient } from "../Forms";

export const DataUploadContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;

  a,
  a:visited {
    color: ${palette.solid.blue};
    text-decoration: none;
    transition: 0.2s ease;
  }

  a:hover {
    color: ${palette.solid.darkblue};
    cursor: pointer;
  }
`;

export const CancelButtonContainer = styled.div`
  height: ${HEADER_BAR_HEIGHT}px;
  display: flex;
  align-items: center;
  position: fixed;
  top: ${HEADER_BAR_HEIGHT}px;
  right: 16px;
  z-index: 2;
`;

export const Instructions = styled.div`
  height: 100%;
  padding: 48px;
  display: flex;
  width: 50%;
  flex-direction: column;
  ${typography.sizeCSS.normal};

  h1 {
    ${typography.sizeCSS.title};
  }

  h2 {
    ${typography.sizeCSS.large};
    margin: 48px 0 16px;
  }

  h3 {
    ${typography.sizeCSS.normal};
    font-weight: 500;
    margin-top: 16px;
  }

  h1,
  h2,
  h3 {
    text-transform: capitalize;
  }

  ol,
  ul,
  p {
    margin: 16px 0;
  }

  ol,
  ul {
    line-height: 1.8rem;
  }

  li {
    margin-left: 16px;
  }

  li ul {
    margin: 0;
  }

  table {
    max-width: 50%;
    width: max-content;
    margin: 16px 0;
    text-align: left;
    border: 0.5px solid black;
    border-spacing: 0;
    ${typography.sizeCSS.normal};
  }

  thead {
    background: ${palette.highlight.grey2};
  }

  th,
  td {
    border: 0.5px solid black;
    padding: 5px 20px;
  }

  @media only screen and (max-width: ${MIN_DESKTOP_WIDTH}px) {
    width: 100%;
    padding: ${HEADER_BAR_HEIGHT + 24}px 24px 24px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin: 16px 0;
`;

export const ErrorWarningButtonWrapper = styled(ButtonWrapper)`
  width: 100%;
  justify-content: space-between;

  div:not(:last-child) > div {
    background: transparent;
    border: 1px solid ${palette.highlight.grey4};
    border-radius: 4px;

    &:hover {
      background: ${palette.highlight.grey2};
    }
  }

  div:last-child > div {
    background: ${palette.solid.blue};
    color: ${palette.solid.white};

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const DownloadAutogeneratedTemplateBox = styled.div`
  ${typography.sizeCSS.normal};
  min-width: 200px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 3px;
  gap: 16px;
  text-transform: capitalize;
  background: none;
  border: 1px solid ${palette.highlight.grey4};

  &:hover {
    cursor: pointer;
    background: ${palette.highlight.grey1};
  }
`;

export const DownloadTemplateSystem = styled.div`
  color: ${palette.solid.darkgrey};

  span {
    ${typography.sizeCSS.small};
    display: block;
    width: fit-content;
    text-decoration: none;
    color: ${palette.solid.blue};
  }
`;

export const UploadButtonLabel = styled.label`
  display: inline-block;
  border-bottom: 1px solid ${palette.solid.white};

  &:hover {
    cursor: pointer;
  }
`;

export const UploadButtonInput = styled.input`
  display: none;
`;

export const Icon = styled.img<{ grayscale?: boolean }>`
  width: 16px;
  aspect-ratio: auto;
  margin-left: 10px;
  ${({ grayscale }) => grayscale && `filter: grayscale(1);`}
`;

export const UploadFileContainer = styled.div`
  height: 100%;
  display: flex;
  position: relative;
`;

// TODO find a way to make only fixed container shrink
export const DragDropContainer = styled.div<{ dragging?: boolean }>`
  height: calc(100% - ${HEADER_BAR_HEIGHT}px);
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  justify-content: center;
  background: ${({ dragging }) =>
    dragging ? palette.solid.darkblue : palette.solid.blue};
  color: ${palette.solid.white};

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media only screen and (max-width: ${MIN_DESKTOP_WIDTH}px) {
    position: fixed;
    height: ${HEADER_BAR_HEIGHT}px;
    width: 100%;
    top: ${HEADER_BAR_HEIGHT}px;
    flex-direction: row;

    div {
      ${typography.sizeCSS.normal};
      flex-direction: row;
      gap: 8px;
    }
  }

  @media only screen and (max-width: ${MIN_TABLET_WIDTH}px) {
    justify-content: center;

    div {
      ${typography.sizeCSS.small};
      flex-direction: column;
      gap: 4px;
      align-items: center;
    }
  }
`;

export const DragDropIconWrapper = styled.div`
  margin-bottom: 16px;

  @media only screen and (max-width: ${MIN_DESKTOP_WIDTH}px) {
    margin-bottom: 0;
    margin-right: 12px;
    height: 22.5px;
    width: 22.5px;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${HEADER_BAR_HEIGHT}px 24px;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media only screen and (max-width: ${MIN_DESKTOP_WIDTH}px) {
    max-width: 100%;
  }
`;

export const Title = styled.div`
  ${typography.sizeCSS.title};
`;

export const ErrorWarningDescription = styled.div`
  ${typography.sizeCSS.normal};
  margin: 8px 0;

  span {
    text-transform: capitalize;
  }
`;

export const MessagesContainer = styled.div`
  width: 100%;
  margin-top: 19px;
`;

type MessageProps = {
  enabled?: boolean | null;
};

export const Message = styled.div<MessageProps>`
  border-top: 1px solid ${palette.highlight.grey2};
  color: ${({ enabled }) =>
    enabled ? palette.solid.darkgrey : palette.highlight.grey10};
`;

export const MetricEnableDescription = styled.div`
  ${typography.sizeCSS.normal};
  width: 100%;
  margin: 0 0 13px 0;
`;

export const SectionHeader = styled.div`
  ${typography.sizeCSS.large};
  margin: 10px 0;

  &:not(:first-child) {
    margin: 40px 0 10px 0;
  }
`;

export const MetricTitle = styled.div`
  ${typography.sizeCSS.medium};
  display: flex;
  align-items: center;
  padding: 12px 0;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const MessageBody = styled.div`
  ${typography.sizeCSS.normal};
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;

  svg {
    position: relative;
    top: 12px;
  }
`;

export const MessageTitle = styled.div`
  display: block;

  span {
    ${typography.sizeCSS.normal};
    color: ${palette.highlight.grey9};
    padding: 2px 10px;
    border: 1px solid ${palette.highlight.grey5};
    border-radius: 2px;
  }
`;

export const MessageSubtitle = styled.div`
  display: block;
`;

export const MessageDescription = styled.div`
  ${typography.sizeCSS.normal};
  width: 100%;
  margin: 8px 0 13px 0;
`;

export const SelectSystemOptions = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const SystemName = styled.div`
  ${typography.sizeCSS.medium};
  padding: 16px 0;
  border-top: 1px solid ${palette.highlight.grey2};
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;

  &:hover {
    cursor: pointer;
    color: ${palette.solid.blue};
  }
`;

export const FileName = styled.div<{ error?: boolean }>`
  ${typography.sizeCSS.medium};
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  color: ${({ error }) => (error ? palette.solid.red : palette.solid.green)};
`;

export const RedText = styled.span`
  color: ${palette.solid.red};
`;

export const BlueText = styled.span`
  color: ${palette.solid.blue};
`;

export const DataUploadLoading = styled.div`
  width: 100%;
  height: calc(100vh - ${FOOTER_HEIGHT_WITHOUT_MARGIN}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoadingHeader = styled.div`
  ${typography.sizeCSS.large};
  display: flex;
  margin: 20px 0 5px 0;
  gap: 3px;
`;

export const LoadingSubheader = styled.div`
  ${typography.sizeCSS.normal};
`;

type CheckIconProps = {
  enabled?: boolean | null;
};

export const CheckIcon = styled.img<CheckIconProps>`
  width: 16px;
  margin-right: 5px;
  ${({ enabled }) => !enabled && `filter: grayscale(1);`};
`;

export const ExtendedOpacityGradient = styled(OpacityGradient)`
  height: 50px;
  position: fixed;
  bottom: 0;
`;

export const ListOfModifiedRecordsContainer = styled.div`
  width: 100%;
  max-height: 130px;
  overflow: auto;
  margin-top: 16px;
  padding: 0 16px;
  color: ${palette.highlight.grey9};
`;

export const ModifiedRecordTitle = styled.div``;

export const ExistingRecordsWrapper = styled.div`
  margin-bottom: 8px;
`;

export const MiniLoaderWrapper = styled.div`
  position: absolute;
  z-index: 3;
  margin-left: 16px;
  margin-top: 8px;
`;

export const CustomOrGenericTemplateRadioButtons = styled.div`
  ${typography.sizeCSS.normal}
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 8px;
`;

export const InputLabelWrapper = styled.div`
  ${typography.sizeCSS.normal}
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TemplateDescription = styled.div`
  ${typography.sizeCSS.normal}
  color: ${palette.highlight.grey9};
`;
